









































































































































































import '@/icons/minimized'
import {auth, firestore, messaging} from '@/plugins/firebase.init';
import {Watch} from 'vue-property-decorator'
import {onAuthStateChanged} from 'firebase/auth'
import Component, {mixins} from 'vue-class-component'
import {Action, Getter, Mutation} from 'vuex-class'
import {doc, setDoc, Unsubscribe} from 'firebase/firestore'
import {applicationStore} from '@/store/modules/application'
import {directoryStore} from '@/store/modules/directory/directoryStore'
import {buildDate, buildVersion, messagingOptions} from '@/plugins/firebase.config'
import {chatStore} from '@/store/modules/chat/ChatStore'
import {profileStore} from '@/store/modules/profile'
import {videoCallStore} from '@/store/modules/video/videoCallStore'
import {requestsStore} from '@/store/modules/requests/RequestsStore'
import {settingsStore} from '@/store/modules/settings'
import GpNotification from '@/components/custom/Notification.vue'
import GpUndo from '@/components/custom/Undo.vue'
import Notifications from '@/components/mixins/Notifications';
import {businessStore} from '@/store/modules/business/businessStore';
import {unsubscribeSafe} from '@/utils/helpers';
import {archiveStore} from '@/store/modules/archive/ArchiveStore';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import ToolTip from '@/components/custom/ToolTip.vue'
import {channelsStore} from '@/store/modules/channels';
import Tour from '@/components/Tour.vue'
import {tourStore} from '@/store/modules/tour'
import {RolesList} from '@/domain/model/types'
import DialogTour from '@/components/DialogTour.vue'
import DialogInfoCenter from '@/components/DialogInfoCenter.vue'
import DialogSetupStatus from '@/components/DialogSetupStatus.vue';
import {getToken} from 'firebase/messaging';
import constants from '@/common/constants';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate']);

interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}


@Component({
  name: 'main-page',
  components: {
    GpNotification, GpUndo, AvatarWithStatus, ToolTip, Tour, DialogTour, DialogInfoCenter, DialogSetupStatus,
    VideoCallMinimized: () => import('@/components/VideoCallMinimized.vue'),
    DialogVideoCallFullScreen: () => import('@/components/DialogVideoCallFullScreen.vue'),
    NavItem: () => import('@/components/NavItem.vue'),
  }
})
export default class MainPage extends mixins(Notifications) {
  private isOffline: boolean = false
  private fullName: string | null = ''
  private subnavItems: any[] = []
  private clipped: boolean = false
  private drawer: boolean = true
  private fixed: boolean = false
  private items: object = [{
    icon: 'close',
    title: 'PIGEON BUSINESS ADMIN'
  }];
  private miniVariant: boolean = false
  private right: boolean = true
  private rightDrawer: boolean = false
  private title: string = 'PIGEON BUSINESS ADMIN'
  private routerName: string = ''
  // isActive: boolean =  false,
  isSharedSelected: boolean = false;
  isActiveSelected: boolean = false;
  isInnerSelected: boolean = false;
  authUnsubscribe: Unsubscribe | null = null;
  unsubscribeUpdater: Unsubscribe | null = null;
  unsubscribeActive?: Unsubscribe | null;
  unsubscribeInner?: Unsubscribe | null;
  unsubscribeInbox?: Unsubscribe | null;
  unsubscribeRejected?: Unsubscribe | null;
  unsubscribeAppointments?: Unsubscribe | null;
  unsubscribeContacts?: Unsubscribe | null;
  unsubscribeCustomers?: Unsubscribe | null;
  unsubscribeArchive?: Unsubscribe | null;
  unsubscribeChannels?: Unsubscribe | null;
  tourInterval: any = undefined
  showCompliteTour: boolean = false
  isInfoPanel: boolean = false
  isInfoItems: boolean = false;  
  unread: any = {
    request: {},
    inner: {},
    active: {},
  }

  @Getter getRequestUnread
  @Getter innerUnread
  @Getter activeUnread
  @Getter inner
  @Getter activeChats
  @Getter arch
  @Getter fulfilled: any
  @Getter videoMinimized?: boolean;
  @Mutation setVideoMinimized;
  @Action clearState

  @Action('loadActive') loadActive;
  @Action('loadInner') loadInner;
  @Action loadCustomers

  private callbackfn = (item) => !!item?.unread && item.unread[this.t2bUser!.id!] > 0;
  private callbackUnread = (after, before) => {
    return !!after.some((afterItem) => {
      const item = before.find((beforeItem) => beforeItem.id === afterItem.id)
      if (!!item) {
        return item.unread > afterItem.unread
      }
      return false
    })
  }

  get showVideoCall() {
    console.log(`MAIN_PAGE -> get showVideoCall()`)
    return videoCallStore.outgoingVideoCall || videoCallStore.incomingVideoCall
  }

  set showVideoCall(value) {
    console.log(`MAIN_PAGE -> set showVideoCall(${value})`)
    videoCallStore.setOutgoingVideoCall(value)
    videoCallStore.setIncomingVideoCall(value)
  }

  get showTour() {
    return tourStore.tourNumber > 0
  }

  set showTour(value) {
    tourStore.changeTour(-1)
  }

  get showVideoMinimized() {
    return this.videoMinimized;
  }

  set showVideoMinimized(value) {
    this.setVideoMinimized(value);
  }

  get buildVersion() {
    return buildVersion;
  }

  get buildDate() {
    return buildDate;
  }

  get currentPage() {
    return applicationStore.currentPage;
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get currentUser() {
    return profileStore.currentUser;
  }

  get isOwner() {
    return applicationStore.isOwner;
  }

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  get collections() {
    return applicationStore.collections;
  }

  get business() {
    return applicationStore.business;
  }

  get needUpdate() {
    return false; //applicationStore.needUpdate
  }

  get isBusinessExist() {
    return applicationStore.isBusinessExist;
  }

  get photoUrl() {
    return this.t2bUser && this.t2bUser.photoUrl && this.t2bUser.photoUrl.thumbnail || '';
  }

  get isHomeSelected() {
    return this.currentPage === 1;
  }

  get isRequestsSelected() {
    return this.currentPage === 2;
  }

  get isChatsSelected() {
    return this.currentPage === 3;
  }

  get isDirectorySelected() {
    return this.currentPage === 4;
  }

  get isDashboardSelected() {
    return this.currentPage === 5;
  }

  get isBusinessPageSelected() {
    return this.currentPage === 6;
  }

  get isSettingsSelected() {
    return this.currentPage === 7;
  }

  get isEnterpriseSelected() {
    return this.currentPage === 8;
  }

  get isNotificationsSelected() {
    return this.currentPage === 9;
  }

  get isFaqSelected() {
    return this.currentPage === 10;
  }

  get isProfileSelected() {
    return this.currentPage === 11;
  }

  get chatsCount() {
    const innerCount = this.inner?.filter(this.callbackfn).length || 0;
    const activeCount = this.activeChats?.filter(this.callbackfn).length || 0;
    return innerCount + activeCount
  }

  get newChatsCount() {
    return this.chatsCount > 99 ? '99+' : this.chatsCount;
  }

  get newRequestsCount() {
    const count = requestsStore.getNewInbox;
    return count > 99 ? '99+' : count;
  }

  get signedIn() {
    return this.currentUser && this.currentUser.uid;
  }

  get verifyingHint() {
    return 'The verification process can take 24 hours.<br>' +
        'Until your business is verified it will not appear to customers,<br>' +
        'but you can continue to add your team mates<br>' +
        'and build your directory while you wait.'
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  get tourAction() {
    return tourStore.tourAction
  }

  get tourBusy() {
    return tourStore.tourBusy
  }

  get appClass() {
    return tourStore.tourNumber > 0 ? `tour-${tourStore.tourNumber}` : ''
  }

  private async logOut() {
    try {
      await applicationStore.signOut();
      await this.$router.push({name: 'auth'});
    } catch (e) {
      console.error('sign-out failed. error: ' + e);
    }
  }


  private onShowDrawer() {
    this.drawer = true;
  }

  private dashboardSelected() {
    this.hideInfoCenter()
    this.$router.push({name: 'dashboard'});
  }

  private businessPageSelected() {
    this.hideInfoCenter()
    if (this.isBusinessPageSelected) {
      return
    }
    this.$router.push({name: 'users', query: {tab: '0'}});
  }

  private contactsSelected() {
    this.hideInfoCenter()
    if (this.isDirectorySelected) {
      return
    }
    this.$router.push('/contacts/directory');
  }

  private chatsSelected() {
    this.hideInfoCenter()
    if (this.isChatsSelected) {
      return
    }
    if (chatStore.lastVisitedChat) {
      this.$router.push({path: chatStore.lastVisitedChat})
      return;
    }
    const subtype = applicationStore.isAdmin ? 'all' : 'personal'
    this.$router.push({name: 'active', params: {chatId: ''}, query: {type: 'active', subtype}});
  }

  private requestsSelected() {
    this.hideInfoCenter()
    if (this.isRequestsSelected) {
      return
    }
    if (requestsStore.lastVisitedRequest) {
      this.$router.push({path: requestsStore.lastVisitedRequest})
      return;
    }
    const subtype = applicationStore.isAdmin ? 'all' : 'personal'
    this.$router.push({name: 'inbox', params: {chatId: ''}, query: {type: 'inbox', subtype}});
  }

  private homeSelected() {
    this.hideInfoCenter()
    if (this.isHomeSelected) {
      return
    }
    this.$router.push({name: 'get-started'});
  }

  private settingsSelected() {
    this.hideInfoCenter()
    if (this.isSettingsSelected) {
      return
    }
    this.$router.push({name: 'settings-localisation'});
  }

  private enterpriseSelected() {
    this.hideInfoCenter()
    applicationStore.selectPage(8);
    this.$router.push({name: 'enterprise'});
  }

  private notificationsSelected() {
    this.hideInfoCenter()
    applicationStore.selectPage(9);
    this.$router.push({name: 'notifications'});
  }

  private async infoCenterSelected() {        
    this.showInfoCenter();
  }

  private async faqSelected() {
    window.open(constants.PIGEON_PLATFORM_HELP_CENTER, '_blank')
  }

  private profileSelected() {
    this.hideInfoCenter()
    if (this.isProfileSelected) {
      return
    }
    this.$router.push({name: 'profile-overview', query: {tab: 'general'}});
  }  

  private onSubnavItemSelected(navItem) {
    if (navItem.selected) {
      return;
    }
    this.subnavItems.forEach((item) => item.selected = item.title === navItem.title);
  }

  private async initActive() {
    this.unsubscribeActive = await this.loadActive()
  }

  private async initInner() {
    this.unsubscribeInner = await this.loadInner()
  }

  private async initInbox() {
    this.unsubscribeInbox = await requestsStore.loadInbox();
  }

  private async initRejected() {
    this.unsubscribeRejected = await requestsStore.loadRejected();
  }

  private async initAppointments() {
    this.unsubscribeAppointments = await requestsStore.loadAppointments();
  }

  private async initContacts() {
    this.unsubscribeContacts = await directoryStore.loadContacts();
  }

  private async initCustomers() {
    this.unsubscribeCustomers = await this.loadCustomers()
  }

  private async initArchive() {
    this.unsubscribeArchive = await archiveStore.loadArchive()
  }

  private async initChannels() {
    this.unsubscribeChannels = await channelsStore.loadChannels()
  }

  private initAuth() {
    this.fullName = this.t2bUser?.fullName
    this.authUnsubscribe = onAuthStateChanged(auth, async (user) => {
          if (!user || !user.refreshToken) {
            return applicationStore.signOut();
          }
        },
        (error) => {
          this.showIssue(error.message)
        });
  }

  private async initUpdateListener() {
    this.unsubscribeUpdater = await applicationStore.listenForUpdates()
  }

  private relativeElement() {
    const element: HTMLElement | null = document.querySelector(`#app.tour-${tourStore.tourNumber} ${tourStore.tour.querySelector.relative}`)
    if (element !== null) {
      tourStore.setTourRelativeObj(element.getBoundingClientRect())
    }
  }

  private selectedElement() {
    const element: HTMLElement | null = document.querySelector(`#app.tour-${tourStore.tourNumber} ${tourStore.tour.querySelector.selected}`)
    if (element !== null) {
      tourStore.setTourSelectedObj(element.getBoundingClientRect())
    }
  }

  private async preCheckTour() {
    let isTour: boolean = false
    let scrollElement: HTMLElement | null
    const windowHeight: number = (window.innerHeight || document.documentElement.clientHeight)
    isTour = await tourStore.checkTour({tourNumber: 8, role: RolesList.superAdmin})
    if (isTour && tourStore.tourRelativeObj) {
      if (tourStore.tourRelativeObj.bottom > windowHeight) {
        scrollElement = document.querySelector('[data-scroll=tabWrapper]')
        if (scrollElement) {
          scrollElement.scrollTop = tourStore.tourRelativeObj.bottom - windowHeight + tourStore.tourRelativeObj.height
          this.relativeElement()
        }
      }
      if (tourStore.tourRelativeObj.bottom > windowHeight - tourStore.tourRelativeObj.height - 250) {
        tourStore.setTourAddition({
          arrow: {side: 'bottom', position: 'left'},
          position: {left: {_left: 0}, top: {top: -253}},
        })
      }
    }
  }

  private async checkTour() {
    if (tourStore.tourNumber === 0 && tourStore.tourCompleted) {
      this.showCompliteTour = true
    }

    clearInterval(this.tourInterval)
    if (tourStore.tourAction) {
      return
    }

    this.tourInterval = setInterval(async () => {
      const element: HTMLElement | null = null
      if (tourStore.tourRelativeObj === null && tourStore.tour.querySelector.relative !== null) {
        this.relativeElement()
      }
      if (tourStore.tourSelectedObj === null && tourStore.tour.querySelector.selected !== null) {
        this.selectedElement()
      }
      if (tourStore.tourRelativeObj !== null &&
          (
              (tourStore.tourSelectedObj !== null && tourStore.tour.querySelector.selected !== null) ||
              (tourStore.tourSelectedObj === null && tourStore.tour.querySelector.selected === null)
          )) {
        await this.preCheckTour()
        clearInterval(this.tourInterval)
        setTimeout(() => {
          tourStore.setTourBusy(false)
        }, 0)
      }
    }, 500)
  }

  @Watch('tourNumber')
  private onChangeTourNumber() {
    this.checkTour()
  }

  @Watch('tourAction')
  private onChangeTourAction() {
    this.checkTour()
  }

  @Watch('getRequestUnread', {deep: true})
  private onChangeRequestUnread(after) {
    this.updateUnread('request', after)
  }

  @Watch('innerUnread', {deep: true})
  private onChangeInnerUnread(after) {
    this.updateUnread('inner', after)
  }

  @Watch('activeUnread', {deep: true})
  private onChangeActiveUnread(after) {
    this.updateUnread('active', after)
  }

  updateUnread(key, items) {
    let notify = false
    items.forEach((item) => {
      if ((!this.unread[key][item.id] && item.unread > 0) ||
          (!!this.unread[key][item.id] && item.unread > this.unread[key][item.id])) {
        const isCurrentChat = this.$router.currentRoute.params.chatId === item.id;
        const isInbox = this.$router.currentRoute.name === 'inbox-request';
        const isInner = this.$router.currentRoute.name === 'inner-chat';
        const isActive = this.$router.currentRoute.name === 'active-chat';
        if (!(key === 'request' && isInbox && isCurrentChat) &&
            !(key === 'inner' && isInner && isCurrentChat) &&
            !(key === 'active' && isActive && isCurrentChat)
        ) {
          notify = true
        }
      }
      this.unread[key][item.id] = item.unread
    })
    this.unread[key] = []
    items.forEach((item) => this.unread[key][item.id] = item.unread)
    if (notify) {
      console.log('updateUnread key: ', key)
      let workingStatus = this.t2bUser.workingStatus?.type
      let doNotDisturb = this.t2bUser.doNotDisturb
      if (workingStatus == 1 && doNotDisturb == undefined){
        console.log('notification sound triggered')
        this.notifyNewMessage(key === 'request' ? key : 'chat')
      }
    }
    notify = false
  }

  private onOnline() {
    this.showInfo('Back online')
    this.isOffline = !navigator.onLine
  }

  private onOffline() {
    this.showIssue('Offline')
    this.isOffline = !navigator.onLine
  }

  private async saveMessagingDeviceToken() {
    try {
      const currentToken = await getToken(messaging, messagingOptions);
      if (currentToken) {
        // Saving the Device Token to the datastore.
        const data = {
          uid: profileStore.currentUser?.uid,
          platform: 'web'
        };
        try {
          await setDoc(doc(firestore, 'fcmTokens', currentToken), data)
        } catch (e: any) {
          console.error('Failed to save fcmToken.', e);
        }
      } else {
        // Need to request permissions to show notifications.
        try {
          await this.requestNotificationsPermissions();
        } catch (e: any) {
          console.error('Failed to request notification permissions.', e);
        }
      }
    } catch (e: any) {
      console.error('Unable to get messaging token.', e);
    }
  }

  private async requestNotificationsPermissions() {
    try {
      await Notification.requestPermission();
      await this.saveMessagingDeviceToken();
    } catch (error) {
      console.error('Unable to get permission to notify.', error);
    }
  }

  private unsubscribeAll() {
    unsubscribeSafe(this.authUnsubscribe)
    unsubscribeSafe(this.unsubscribeActive)
    unsubscribeSafe(this.unsubscribeInner)
    unsubscribeSafe(this.unsubscribeInbox)
    unsubscribeSafe(this.unsubscribeRejected)
    unsubscribeSafe(this.unsubscribeAppointments)
    unsubscribeSafe(this.unsubscribeUpdater)
    unsubscribeSafe(this.unsubscribeContacts)
    unsubscribeSafe(this.unsubscribeCustomers)
    unsubscribeSafe(this.unsubscribeArchive)
    unsubscribeSafe(this.unsubscribeChannels)
  } 

  // status
  get createAccountDone() {
    return true;
  }

  get inviteDone() {
    return !!businessStore.invited?.length;
  }

  get createBusinessDone() {
    return !!directoryStore.directoryContactsCount;
  }

  get completeProfileDone() {
    return !!applicationStore?.business?.zipCode;
  }

  get discoverDone() {    
    return !!profileStore.discoverd;
  }

  get setupCompleted(){    
    return (
      (
        Number(this.createAccountDone) + Number(this.inviteDone) 
        + Number(this.createBusinessDone)
        + Number(this.completeProfileDone) 
        + Number(this.discoverDone)
      ) !== 5
    );
  }
 
  private async checkSetupCompletedStatus() {
    if(this.isOwner){
      await businessStore.loadInvited();
      if(this.setupCompleted){        
        this.showReadSetup();  
        this.showInfoCenter();  
      }
    }
  } 
  
  private showInfoCenter() {
    this.isInfoPanel = true;
  }

  private hideInfoCenter() {
    this.isInfoPanel = false;
  }

  private showReadSetup() {
    //this.isInfoItems = true;  // need to enable
    if(!this.isOldUser()){
      this.isInfoItems = true;
    }
  }

  private onReadSetup() {    
    this.isInfoPanel = false;    
    setTimeout(() => this.isInfoPanel = true, 100);  
  }

  private isOldUser() {
    // for new users only,  November 1, 2024    
    const createdDate = applicationStore?.business?.createdDate as FirestoreTimestamp | null;    
    if (!createdDate || typeof createdDate.seconds === 'undefined' || typeof createdDate.nanoseconds === 'undefined') {
      return true;
    }

    const dateToCompare = new Date(createdDate?.seconds * 1000 + createdDate?.nanoseconds / 1e6); 
    const validFrom =  new Date('2024-11-01T00:00:00Z');
    return dateToCompare < validFrom;
  }
 
  
  private async created() {
    window.addEventListener('online', this.onOnline);
    window.addEventListener('offline', this.onOffline);

    const isHowToUse = window.localStorage.getItem('isHowToUse') || ''; 
    window.localStorage.clear();
    window.localStorage.setItem('isHowToUse', isHowToUse);
    
    this.unsubscribeAll()
    profileStore.attachStatus()
    try {
      await this.saveMessagingDeviceToken()
      await this.initInbox()
      await this.initRejected()
      await this.initAppointments()
      await this.initActive();
      await this.initInner();
      await this.initArchive();
      await this.initChannels()
      await this.checkSetupCompletedStatus();
    } catch (e) {
      console.error(e);
    }
    this.initAuth()
    await this.initUpdateListener()
  }

  private beforeDestroy() {
    window.removeEventListener('online', this.onOnline)
    window.removeEventListener('offline', this.onOffline)
    this.unsubscribeAll()
    this.clearState()
  }

  private beforeRouteUpdate(to, from, next) {
    this.routerName = to.name
    next()
  }

  private async beforeRouteEnter(to, from, next) {
    try {
      await profileStore.refreshUser()
      await applicationStore.loadBusiness()
      await applicationStore.loadBusinessCategories()
      await applicationStore.loadSmsBusiness()
      await settingsStore.readSettings()
      await businessStore.loadAssociates()
    } catch (e) {
      console.error(e);
    }
    next(async (vm) => {
      vm.routerName = to.name
      await vm.initContacts()
      await vm.initCustomers()
    });
  }
}
